<template>
    <div>
        <clinics-component/>
        <footer-component/>
    </div>
</template>

<script>
    import ClinicsComponent from "@/components/hospitals-clinics/ClinicsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Clinics",
        title: "Catálogo de Clínicas | Turismo BC",
        components: {FooterComponent, ClinicsComponent}
    }
</script>

<style scoped>

</style>